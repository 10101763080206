<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <section class="mb-4">
      <div class="container">
        <page-loading :show="loading" />
        <div class="wrapper--title">
          <!--          <h4 class="modal-title">{{ $t('modal.signFkp') }}</h4>-->
          <hr />
        </div>
        <div class="wrapper--main">
          <div style="overflow-x: scroll" v-html="digitalSignTemplate"></div>
          <div class="wrapper--footer">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="checkboxagreement"
                name="agreement"
                v-model="agreement"
              />
              <label class="custom-control-label" for="checkboxagreement">
                {{ $t('booking.messageAgreement') }}</label
              >
            </div>
            <div class="d-flex mt-2">
              <button class="btn btn-primary mr-2" @click="sign" :disabled="!agreement">
                {{ $t('booking.sign') }}
              </button>
              <button
                @click="openCancelBookModal"
                class="btn btn-danger color-white mx-1"
                type="button"
                :disabled="agreement"
              >
                {{ $t('booking.notAgree') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <reject-sell-modal @actionDone="backToDetail" />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading');

const RejectSellModal = () =>
  import('@/components/application-action/modals/sell-transaction/reject-modal');
export default {
  name: 'booking-sign',
  components: {
    DefaultLayout,
    PageLoading,
    RejectSellModal,
  },
  async fetch({ store, params, router, query, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    const uuid = params.uuid;
    store.commit('sellTransaction/SET_SELL_TRANSACTION_UUID', uuid);
    try {
      const { data } = await store.dispatch('sellTransaction/checkSignFkp', uuid);
      if (!data) router.replace('/');
    } catch (_) {
      router.replace('/');
    }
    await store.dispatch('sellTransaction/getFkpTemplate', query.owner_unique_number);
  },
  data() {
    return {
      agreement: false,
    };
  },
  computed: {
    ...mapState({
      bookingInfo: (state) => state.v2.booking.detail.bookingInfo,
      digitalSignTemplate: (state) => state.sellTransaction.fkpTemplate,
      loading: (state) => state.sellTransaction.fkpSignLoading,
    }),
    breadcrumbs() {
      if (!this.bookingInfo) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('dashboard.myBooking'),
          exact: true,
          to: '/dashboard/mybooking',
        },
        {
          text: `${this.$t('booking.number')} ${
            this.bookingInfo && this.bookingInfo.order_number
              ? this.bookingInfo.order_number
              : this.$route.params.uuid
          }`,
          to: `/mybooking/detail?type=SELL_TRANSACTION&uuid=${this.$route.params.uuid}`,
        },
        {
          text: this.$t('breadcrumb.signFkp'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {
    openCancelBookModal() {
      this.$modal.show('sell-reject-modal', {
        uuid: this.$route.params.uuid,
        buttonName: 'Batalkan Reservasi',
      });
    },
    async sign() {
      await this.$store.dispatch('sellTransaction/signFkp');
      await this.$swal(
        this.$t('general.success'),
        this.$t('booking.swal.signFkp.message'),
        'success',
      );
      this.backToDetail();
    },
    backToDetail() {
      this.$router.replace(
        `/mybooking/detail?type=SELL_TRANSACTION&uuid=${this.$route.params.uuid}`,
      );
    },
  },
};
</script>

<style scoped></style>
